import React from "react"
import SEO from "../components/seo"
import Layout from "../components/layout"
import Thursday from "../components/images/thursday"
import Friday from "../components/images/friday"
import Saturday from "../components/images/saturday"

import Logos from "../components/images/logos"

const IndexPage = () => (
  <Layout>
    <SEO title="Halloween | Soubois" description="Halloween @ Soubois 2019" />

    <div className="logoLock">
      <img src="/soubois.png" style={{ width: "300px " }} />
      <img src="/diadelosmuertos.png" />
    </div>
    <div className="videoBox" style={{ width: "100%", textAlign: "center" }}>
      <video src="/halloween.mp4" autoPlay muted loop playsInline controls />
    </div>
    <div className="logos">
      <Logos />
    </div>
    <div className="events">
      <div className="event">
        <div>
          <Thursday />
        </div>
        <div className="content">
          <h4>October 31</h4>
          <h3>Cazar</h3>
          <h2>Behrouz</h2>
          <h2>Whomadewho</h2>
          <h2>Laurence Matte</h2>
          <div className="buttons">
            <a
              href="https://www.themrblack.com/embed/soubois/form?date=2019-10-31&eventId=562"
              className="button"
              target="_blank"
              rel="noopener noreferer"
            >
              Table Reservations
            </a>
            <br />
            <a
              href="https://www.eventbrite.com/e/do-not-sit-at-cazar-halloween-edition-with-behrouz-friends-tickets-73210286939"
              className="button"
              target="_blank"
              rel="noopener noreferer"
            >
              Buy Tickets
            </a>
          </div>
        </div>
      </div>
      <div className="event">
        <div>
          <Friday />
        </div>
        <div className="content">
          <h4>November 1</h4>
          <h2>Nico de Andrea (Cannes)</h2>
          <h2>Mekki</h2>
          <div className="buttons">
            <a
              href="https://themrblack.com/embed/soubois/reservation?date=2019-11-01&eventId=560"
              className="button"
              target="_blank"
              rel="noopener noreferer"
            >
              Table Reservations
            </a>
          </div>
        </div>
      </div>
      <div className="event">
        <div>
          <Saturday />
        </div>
        <div className="content">
          <h4>November 2</h4>
          <h3>Day of the Dead</h3>

          <h2>Thomas H (All Night)</h2>
          <div className="buttons">
            <a
              href="https://www.themrblack.com/embed/soubois/form?date=2019-11-02&eventId=561"
              className="button"
              target="_blank"
              rel="noopener noreferer"
            >
              Table Reservations
            </a>
          </div>
        </div>
      </div>
    </div>
    <div
      className="reservations"
      style={{
        width: "80%",
        margin: "0 auto",
        fontSize: "1.5em",
        textAlign: "center",
        lineHeight: "1.5em",
      }}
    >
      For Dinner Reservations:
      <br />
      <a href="mailto:rsvp@soubois.com">rsvp@soubois.com</a>
      <br />
      <a href="tel:5145643672">(514) 564-3672</a>
    </div>
    <div style={{ textAlign: "center", margin: "50px auto" }}>
      <a href="https://soubois.com" style={{ color: "white" }}>
        soubois.com
      </a>
    </div>
  </Layout>
)

export default IndexPage
